import React from "react";
import "./About.css"; // Import the CSS file

function About() {
  console.log("Calling About");
  return (
    <div>
      <div className="banner">
        <span>Welcome to ProjexLight Cloud Platform</span>
      </div>

      <div className="about-container">
        <div className="about-left-section">
          Project Management & Test Execution Platform
        </div>

        <div className="about-right-section">
          <div className="about-box">
            <div className="video-container">
              <iframe
                src="https://www.youtube.com/embed/kvRHIofLF_I"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className="about-box">
            <h1>What Can You Do?</h1>
            <ul>
              <li>
                <div>
                  Create project management tasks, write BDD test cases, execute
                  hundreds of test suites in parallel for any test environment
                  using your preferred cloud and Docker, and report everything
                  within a single platform.
                </div>
              </li>
              <li>
                <div>
                  Connect with developers and testers worldwide to collaborate
                  on application development projects.
                </div>
              </li>
              <li>
                <div>
                  Manage and test your projects using this cloud-based
                  application—try it out for free.
                </div>
              </li>
              <li>
                <div>
                  Use your own private cloud account, develop and test suites,
                  run tests, capture and assign defects, fix and rerun tests,
                  build CI/CD stages, and launch tests within our application or
                  anywhere using our test cycle API.
                </div>
              </li>
              <li>
                <div>
                  Hire professionals based on their skill set, assign tasks, and
                  collaborate with developers globally.
                </div>
              </li>
            </ul>
          </div>

          <div className="about-box">
            <h1>Who Can Use This Software?</h1>
            <ul>
              <li>
                <div>
                  Startups looking for a cloud-based project, task, test, and
                  defect management software with CI/CD capability can use this
                  platform. If you want to execute tests and generate reports
                  within a single platform using your preferred cloud, such as
                  AWS or Azure, this platform is for you.
                </div>
              </li>
              <li>
                <div>
                  Students working on academic projects with a team can use this
                  platform for project collaboration.
                </div>
              </li>
              <li>
                <div>
                  Freelancers managing multiple clients for different projects
                  remotely can collaborate with clients within a single
                  platform.
                </div>
              </li>
              <li>
                <div>
                  Enterprises looking to try out this software for projects
                  before purchasing the independent enterprise version can start
                  using this software online.
                </div>
              </li>
              <li>
                <div>
                  Students and fresh graduates searching for internship projects
                  can sign up, find opportunities, and start working remotely.
                </div>
              </li>
            </ul>
          </div>

          <div className="about-box">
            <h1>How Much Does It Cost?</h1>
            <ul>
              <li>
                <div>
                  Students and fresh graduates get their first project for free.
                  Use your own AWS/Azure subscription to build test suites, run
                  tests, and manage your projects, defects, and tasks at no
                  cost.
                </div>
              </li>
              <li>
                <div>
                  Startups and enterprises can create a few trial test suites,
                  use their AWS/Azure subscription, run tests, and generate
                  reports for one month. If additional trial time is needed, it
                  can be granted on a case-by-case basis.
                </div>
              </li>
              <li>
                <div>
                  During your trial, if you need assistance, our support team is
                  available. Feel free to create a support ticket, and we will
                  be happy to help.
                </div>
              </li>
            </ul>
          </div>

          <div className="about-box">
            <h1>About Us</h1>
            <ul>
              <li>
                <div>
                  We are a Silicon Valley startup with decades of experience in
                  project management, test management, automation, and test
                  execution. Our cloud-based, all-in-one solution provides a
                  complete overview of your project, enabling you to execute
                  thousands of test suites in parallel with high speed and
                  resilience using the cloud provider of your choice.
                </div>
              </li>
              <li>
                <div>
                  Reduce your project testing and delivery time to just a few
                  hours by leveraging the power of parallel processing. This
                  approach minimizes costs, accelerates timelines, and enhances
                  productivity more efficiently than ever before.
                </div>
              </li>
            </ul>
          </div>

          <div className="about-box">
            <h1>Contact</h1>
            <ul>
              <li className="footer-row">
                <div className="footer-left">©2025 projexlight.com</div>
              </li>
              <li className="footer-row">
                <div className="footer-left">
                  📧 Email:{" "}
                  <a href="mailto:support@projexlight.com">
                    support@projexlight.com
                  </a>
                </div>
                <div className="footer-center">
                  <a href="/terms">Terms & Conditions</a>
                </div>
                <div className="footer-right">
                  <a href="/privacy">Privacy Policy</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
