import React from "react";
import "./FAQ.css";

function FAQ() {
  return (
    <div className="terms-container">
      <h1>Frequently Asked Questions (FAQ)</h1>

      <section>
        <h2>How secure is my data?</h2>
        <p>
          Your data is completely secure. We use AWS RDS for data storage and
          adhere to strict security policies based on AWS cloud security
          protocols. Your data is accessed through our API only after you log in
          and authenticate your session. All data is partitioned at the user
          level, ensuring that your data is not shared with other users unless
          you explicitly collaborate and assign a project task to them.
        </p>
      </section>

      <section>
        <h2>How can I collaborate with other users?</h2>
        <ul>
          <li>
            To collaborate with developers or QA engineers, you must create a{" "}
            <strong>project board.</strong>
          </li>
          <li>
            You can add members to a project by specifying a start and end date.
            Users can be found using their login ID or email address.
          </li>
          <li>
            You must assign a role to each user, such as Developer, Guest, or
            Admin.
            <ul>
              <li>
                <strong>Developer:</strong> Can access Epics, Features, Tasks,
                and Test Suites.
              </li>
              <li>
                <strong>Guest:</strong> Can access only the Testing area.
              </li>
              <li>
                <strong>Admin:</strong> Has full access to all projects, private
                data, project settings, and configurations.
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <h2>What is the pricing structure?</h2>
        <ul>
          <li>
            During the evaluation period, we provide 200 Credits, equivalent to
            $20.
          </li>
          <li>
            After using the initial credits, you must purchase credits on a
            pay-as-you-go basis.
          </li>
          <li>
            <strong>Pricing breakdown:</strong>
            <ul>
              <li>5 Credits per collaborator per month per project.</li>
              <li>5 Credits per generated Feature file in BDD format.</li>
              <li>1 Credit per Test Suite run on your cloud infrastructure.</li>
            </ul>
          </li>
          <li>
            To run tests on your AWS/Azure cloud, you need to configure your
            access key to allow us to use your private cloud infrastructure.
          </li>
        </ul>
      </section>

      <section>
        <h2>Where are my documents stored, and who owns them?</h2>
        <ul>
          <li>
            To store documents in the cloud or attach files to test cases,
            epics, projects, or feature files, you must configure cloud storage
            via your AWS or Azure account.
          </li>
          <li>
            You must provide cloud access credentials (access key and secret
            key) in the setup menu under "Cloud Configuration." Once saved, your
            access key will not be displayed again for security reasons. These
            credentials are used via API authentication and are never shared.
          </li>
        </ul>
      </section>

      <section>
        <h2>How do I update my AWS/Azure access key?</h2>
        <p>
          Since we do not display your saved access key, you cannot update it
          directly. Instead, you must create a new record with the updated key.
          The system will automatically replace the old key and delete the
          temporary record to ensure that your previously used credentials
          remain valid during transition.
        </p>
      </section>

      <section>
        <h2>Where can I access training materials?</h2>
        <p>
          Once logged in, navigate to the setup menu to find several training
          videos. We highly recommend watching these videos before starting your
          project. If you require further assistance, feel free to contact us.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>If you have any questions, feel free to reach out to us:</p>
        <p>
          📧 Email:{" "}
          <a href="mailto:support@projexlight.com">support@projexlight.com</a>
        </p>
      </section>
    </div>
  );
}

export default FAQ;
