import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext"; // Adjust the import path as needed
import logo from "./logo.png"; // Adjust the import path as needed
import "./Navigation.css";

// Define roles and permissions
const roles = {
  Admin: ["setup", "mytasks", "features", "testing", "defects"],
  Developer: ["mytasks", "features", "testing"],
  Guest: [],
};

function Navigation() {
  const { user } = useContext(AuthContext);
  const userRole = user?.roles || "guest"; // Default to 'guest' if no role is found
  const permissions = roles[userRole] || [];
  const fullname = user?.fullname;
  const shortId = user?.shortid;

  return (
    <nav>
      <ul className="nav-container">
        <div className="nav-left">
          <li>
            <Link to="/">
              <img src={logo} alt="ProjeXLight Logo" className="logo" />
            </Link>
          </li>
          {!fullname && (
            <li>
              <Link to="/about">Home</Link>
            </li>
          )}
          {!fullname && (
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
          )}
          {/* {!fullname && (
            <li>
              <Link to="/joinprojects">Join Projects</Link>
            </li>
          )}
           {!fullname && (
            <li>
              <Link to="/jobs">Jobs</Link>
            </li>
          )}
          {!fullname && (
            <li>
              <Link to="/freelancer">Freelancer</Link>
            </li>
          )} */}

          {fullname && (
            <li>
              <Link to="/home">Home</Link>
            </li>
          )}
          {permissions.includes("setup") && (
            <li>
              <Link to="/setup/setupdetail">Setup</Link>
            </li>
          )}
          {permissions.includes("mytasks") && (
            <li>
              <Link to="/mytasks/taskdetail">My Tasks</Link>
            </li>
          )}
          {permissions.includes("features") && (
            <li>
              <Link to="/features/featuredetail">Feature</Link>
            </li>
          )}
          {permissions.includes("testing") && (
            <li>
              <Link to="/testing/testingdetail">Testing</Link>
            </li>
          )}
          {permissions.includes("defects") && (
            <li>
              <Link to="/defects/defectdetail">Defect</Link>
            </li>
          )}
        </div>
        <div className="nav-right">
          {fullname && (
            <li className="welcome-message">
              <div>Welcome {fullname}</div>
              <div>LoginId: {shortId}</div>
            </li>
          )}
          {fullname && (
            <li>
              <Link to="/logout">Logout</Link>
            </li>
          )}
          {!fullname && (
            <li>
              <Link to="/login">Login</Link>
            </li>
          )}
          {!fullname && (
            <li>
              <Link to="/register">Signup</Link>
            </li>
          )}
        </div>
      </ul>
    </nav>
  );
}

export default Navigation;
