import React from "react";
import "./TermsAndConditions.css";

function TermsAndConditions() {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>

      <section>
        <h2>Introduction</h2>
        <p>
          Welcome to <strong>Projexlight</strong> ("we", "our", "us"), a{" "}
          <strong>
            Project Management, Test Management, and Test Execution platform{" "}
          </strong>
          available to customers in the US and worldwide. These{" "}
          <strong>Terms and Conditions</strong> ("Terms") govern your use of our
          website and services. By accessing or using our website and services,
          you agree to comply with and be bound by these Terms. If you do not
          agree with these Terms, please do not use our services.
        </p>
      </section>

      <section>
        <h2>Services Provided</h2>
        <ul>
          <li>
            <strong>AI-powered test case generation</strong>
          </li>
          <li>
            <strong>Project and test management tools</strong>
          </li>
          <li>
            <strong>Payment processing via third-party gateways</strong>
          </li>
          <li>
            <strong>
              Cloud storage for user data, including profile information and
              test-related content
            </strong>
          </li>
        </ul>
      </section>

      <section>
        <h2>User Responsibilities</h2>
        <h3>Account Registration</h3>
        <ul>
          <li>
            You must provide <strong>accurate and complete information</strong>{" "}
            when registering for an account.
          </li>
          <li>
            You are responsible for{" "}
            <strong>maintaining the confidentiality</strong> of your account
            credentials.
          </li>
          <li>
            You agree to notify us immediately of any{" "}
            <strong>unauthorized access</strong> to your account.
          </li>
        </ul>
      </section>

      <section>
        <h3>Use of Services</h3>
        <ul>
          <li>
            You agree to use our services{" "}
            <strong>only for lawful purposes</strong>.
          </li>
          <li>
            You must not misuse our platform by engaging in{" "}
            <strong>
              illegal activities, infringing on rights, or disrupting services
            </strong>
            .
          </li>
        </ul>
      </section>

      <section>
        <h2>Third-Party Services</h2>
        <h3>AI Provider</h3>
        <p>
          We use an <strong>AI-based system</strong> to generate test cases
          based on user input.
        </p>
        <h3>Payment Processing</h3>
        <p>
          Payments are processed through <strong>third-party gateways</strong>.
          All payment transactions are governed by their respective policies.
        </p>
        <h3>Cloud Storage</h3>
        <p>
          Your data, including profile details and generated test cases, is
          stored securely using <strong>third-party cloud storage</strong>{" "}
          services.
        </p>
      </section>

      <section>
        <h2>Refund Policy</h2>
        <h3>General Refund Policy</h3>
        <p>
          Refunds are <strong>issued at our discretion</strong> and subject to
          case-by-case review. To request a refund, contact{" "}
          <a href="mailto:support@projexlight.com">support@projexlight.com</a>.
        </p>
      </section>

      <section>
        <h2>Liability and Errors</h2>
        <h3>Third-Party Errors</h3>
        <p>
          We are <strong>not responsible</strong> for errors caused by{" "}
          <strong>third-party services</strong>, including AI providers, payment
          gateways, and cloud storage.
        </p>
        <h3>Limitation of Liability</h3>
        <p>
          Our <strong>liability is limited</strong> to the extent{" "}
          <strong>permitted by law</strong>. We are{" "}
          <strong>not responsible</strong> for{" "}
          <strong>indirect, incidental, or consequential damages</strong>.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions regarding these Terms, reach out to us at:
        </p>
        <p>
          📧 Email:{" "}
          <a href="mailto:support@projexlight.com">support@projexlight.com</a>
        </p>
      </section>
    </div>
  );
}

export default TermsAndConditions;
