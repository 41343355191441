import React, { useEffect, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Mode from "./config/runenv";
import config from "./config/config";
import Home from "./navigation/Home";
import About from "./navigation/About";
import Login from "./navigation/Login";
import Logout from "./navigation/LogoutPage";
import Navigation from "./navigation/Navigation";
import PrivateRoute from "./navigation/PrivateRoute";
import Setup from "./navigation/Setup";

import RegistrationForm from "./registration/RegistrationForm";
import PasswordRecovery from "./registration/PasswordRecovery";
import Activation from "./registration/Activation";
import { AuthContext } from "../src/context/AuthContext";
import EditAccount from "./components/account/EditAccount";
import EditAttribute from "./components/attribute/EditAttribute";
import EditBilling from "./components/billing/EditBilling";
import EditBoard from "./components/board/EditBoard";
import EditCloudConfig from "./components/cloudconfig/EditCloudConfig";
import EditCodeAttribute from "./components/codeattribute/EditCodeAttribute";
import EditComments from "./components/comments/EditComments";
import EditCustomFields from "./components/customfields/EditCustomFields";
import EditDefect from "./components/defect/EditDefect";
import EditDefectDocument from "./components/defectdocument/EditDefectDocument";
import EditDockerImage from "./components/dockerimage/EditDockerImage";
import EditEmailSetting from "./components/emailsetting/EditEmailSetting";
import EditEpic from "./components/epic/EditEpic";
import EditFeature from "./components/feature/EditFeature";
import EditFeatureDocument from "./components/featuredocument/EditFeatureDocument";
import EditGenericCode from "./components/genericcode/EditGenericCode";
import EditListProperty from "./components/listproperty/EditListProperty";
import EditMember from "./components/member/EditMember";
import EditNotes from "./components/notes/EditNotes";
import EditObject from "./components/object/EditObject";
import EditProduct from "./components/product/EditProduct";
import EditProfile from "./components/profile/EditProfile";
import EditProject from "./components/project/EditProject";
import EditRelease from "./components/release/EditRelease";
import EditRunSchedule from "./components/runschedule/EditRunSchedule";
import EditRunTag from "./components/runtag/EditRunTag";
import EditScenario from "./components/scenario/EditScenario";
import EditSprint from "./components/sprint/EditSprint";
import EditSteps from "./components/steps/EditSteps";
import EditSubscription from "./components/subscription/EditSubscription";
import EditSubTask from "./components/subtask/EditSubTask";
import EditSuiteTag from "./components/suitetag/EditSuiteTag";
import EditTag from "./components/tag/EditTag";
import EditTaskDocument from "./components/taskdocument/EditTaskDocument";
import EditTasks from "./components/tasks/EditTasks";
import EditTestCycle from "./components/testcycle/EditTestCycle";
import EditTestFailure from "./components/testfailure/EditTestFailure";
import EditTestLog from "./components/testlog/EditTestLog";
import EditTestReport from "./components/testreport/EditTestReport";
import EditTestResult from "./components/testresult/EditTestResult";
import EditTestSuite from "./components/testsuite/EditTestSuite";
import EditTimesheet from "./components/timesheet/EditTimesheet";
import EditUser from "./components/user/EditUser";
import TermsAndConditions from "./navigation/TermsAndConditions";
import PrivacyPolicy from "./navigation/PrivacyPolicy";
import FAQ from "./navigation/FAQ";
function App() {
  const { user, login, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchToken = async () => {
      if (!user) {
        try {
          const response = await axios.post(`${config.AUTH_URL}/login`, {
            logintype: config.USER_TYPE_REGISTRATION,
          });
          login(response.data);
        } catch (error) {
          console.error("Error logging in:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchToken();
  }, [user, login]);

  useEffect(() => {
    console.log("Current user:", user);
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }
  const AccountRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editaccount?time" + Date.now()}
          command="editaccount"
          accountId={id}
        />
        <EditAccount accountId={id} />
      </>
    );
  };
  const AttributeRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editattribute?time" + Date.now()}
          command="editattribute"
          attributeId={id}
        />
        <EditAttribute attributeId={id} />
      </>
    );
  };
  const BillingRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editbilling?time" + Date.now()}
          command="editbilling"
          billingId={id}
        />
        <EditBilling billingId={id} />
      </>
    );
  };
  const BoardRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editboard?time" + Date.now()}
          command="editboard"
          boardId={id}
        />
        <EditBoard boardId={id} />
      </>
    );
  };
  const CloudConfigRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editcloudconfig?time" + Date.now()}
          command="editcloudconfig"
          cloudconfigId={id}
        />
        <EditCloudConfig cloudconfigId={id} />
      </>
    );
  };
  const CodeAttributeRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editcodeattribute?time" + Date.now()}
          command="editcodeattribute"
          codeattributeId={id}
        />
        <EditCodeAttribute codeattributeId={id} />
      </>
    );
  };
  const CommentsRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editcomments?time" + Date.now()}
          command="editcomments"
          commentsId={id}
        />
        <EditComments commentsId={id} />
      </>
    );
  };
  const CustomFieldsRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editcustomfields?time" + Date.now()}
          command="editcustomfields"
          customfieldsId={id}
        />
        <EditCustomFields customfieldsId={id} />
      </>
    );
  };
  const DefectRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editdefect?time" + Date.now()}
          command="editdefect"
          defectId={id}
        />
        <EditDefect defectId={id} />
      </>
    );
  };
  const DefectDocumentRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editdefectdocument?time" + Date.now()}
          command="editdefectdocument"
          defectdocumentId={id}
        />
        <EditDefectDocument defectdocumentId={id} />
      </>
    );
  };
  const DockerImageRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editdockerimage?time" + Date.now()}
          command="editdockerimage"
          dockerimageId={id}
        />
        <EditDockerImage dockerimageId={id} />
      </>
    );
  };
  const EmailSettingRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editemailsetting?time" + Date.now()}
          command="editemailsetting"
          emailsettingId={id}
        />
        <EditEmailSetting emailsettingId={id} />
      </>
    );
  };
  const EpicRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editepic?time" + Date.now()}
          command="editepic"
          epicId={id}
        />
        <EditEpic epicId={id} />
      </>
    );
  };
  const FeatureRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editfeature?time" + Date.now()}
          command="editfeature"
          featureId={id}
        />
        <EditFeature featureId={id} />
      </>
    );
  };
  const FeatureDocumentRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editfeaturedocument?time" + Date.now()}
          command="editfeaturedocument"
          featuredocumentId={id}
        />
        <EditFeatureDocument featuredocumentId={id} />
      </>
    );
  };
  const GenericCodeRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editgenericcode?time" + Date.now()}
          command="editgenericcode"
          genericcodeId={id}
        />
        <EditGenericCode genericcodeId={id} />
      </>
    );
  };
  const ListPropertyRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editlistproperty?time" + Date.now()}
          command="editlistproperty"
          listpropertyId={id}
        />
        <EditListProperty listpropertyId={id} />
      </>
    );
  };
  const MemberRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editmember?time" + Date.now()}
          command="editmember"
          memberId={id}
        />
        <EditMember memberId={id} />
      </>
    );
  };
  const NotesRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editnotes?time" + Date.now()}
          command="editnotes"
          notesId={id}
        />
        <EditNotes notesId={id} />
      </>
    );
  };
  const ObjectRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editobject?time" + Date.now()}
          command="editobject"
          objectId={id}
        />
        <EditObject objectId={id} />
      </>
    );
  };
  const ProductRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editproduct?time" + Date.now()}
          command="editproduct"
          productId={id}
        />
        <EditProduct productId={id} />
      </>
    );
  };
  const ProfileRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editprofile?time" + Date.now()}
          command="editprofile"
          profileId={id}
        />
        <EditProfile profileId={id} />
      </>
    );
  };
  const ProjectRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editproject?time" + Date.now()}
          command="editproject"
          projectId={id}
        />
        <EditProject projectId={id} />
      </>
    );
  };
  const ReleaseRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editrelease?time" + Date.now()}
          command="editrelease"
          releaseId={id}
        />
        <EditRelease releaseId={id} />
      </>
    );
  };
  const RunScheduleRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editrunschedule?time" + Date.now()}
          command="editrunschedule"
          runscheduleId={id}
        />
        <EditRunSchedule runscheduleId={id} />
      </>
    );
  };
  const RunTagRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editruntag?time" + Date.now()}
          command="editruntag"
          runtagId={id}
        />
        <EditRunTag runtagId={id} />
      </>
    );
  };
  const ScenarioRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editscenario?time" + Date.now()}
          command="editscenario"
          scenarioId={id}
        />
        <EditScenario scenarioId={id} />
      </>
    );
  };
  const SprintRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editsprint?time" + Date.now()}
          command="editsprint"
          sprintId={id}
        />
        <EditSprint sprintId={id} />
      </>
    );
  };
  const StepsRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editsteps?time" + Date.now()}
          command="editsteps"
          stepsId={id}
        />
        <EditSteps stepsId={id} />
      </>
    );
  };
  const SubscriptionRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editsubscription?time" + Date.now()}
          command="editsubscription"
          subscriptionId={id}
        />
        <EditSubscription subscriptionId={id} />
      </>
    );
  };
  const SubTaskRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editsubtask?time" + Date.now()}
          command="editsubtask"
          subtaskId={id}
        />
        <EditSubTask subtaskId={id} />
      </>
    );
  };
  const SuiteTagRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"editsuitetag?time" + Date.now()}
          command="editsuitetag"
          suitetagId={id}
        />
        <EditSuiteTag suitetagId={id} />
      </>
    );
  };
  const TagRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup key={"edittag?time" + Date.now()} command="edittag" tagId={id} />
        <EditTag tagId={id} />
      </>
    );
  };
  const TaskDocumentRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"edittaskdocument?time" + Date.now()}
          command="edittaskdocument"
          taskdocumentId={id}
        />
        <EditTaskDocument taskdocumentId={id} />
      </>
    );
  };
  const TasksRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"edittasks?time" + Date.now()}
          command="edittasks"
          tasksId={id}
        />
        <EditTasks tasksId={id} />
      </>
    );
  };
  const TestCycleRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"edittestcycle?time" + Date.now()}
          command="edittestcycle"
          testcycleId={id}
        />
        <EditTestCycle testcycleId={id} />
      </>
    );
  };
  const TestFailureRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"edittestfailure?time" + Date.now()}
          command="edittestfailure"
          testfailureId={id}
        />
        <EditTestFailure testfailureId={id} />
      </>
    );
  };
  const TestLogRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"edittestlog?time" + Date.now()}
          command="edittestlog"
          testlogId={id}
        />
        <EditTestLog testlogId={id} />
      </>
    );
  };
  const TestReportRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"edittestreport?time" + Date.now()}
          command="edittestreport"
          testreportId={id}
        />
        <EditTestReport testreportId={id} />
      </>
    );
  };
  const TestResultRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"edittestresult?time" + Date.now()}
          command="edittestresult"
          testresultId={id}
        />
        <EditTestResult testresultId={id} />
      </>
    );
  };
  const TestSuiteRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"edittestsuite?time" + Date.now()}
          command="edittestsuite"
          testsuiteId={id}
        />
        <EditTestSuite testsuiteId={id} />
      </>
    );
  };
  const TimesheetRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"edittimesheet?time" + Date.now()}
          command="edittimesheet"
          timesheetId={id}
        />
        <EditTimesheet timesheetId={id} />
      </>
    );
  };
  const UserRouteWrapper = () => {
    const { id } = useParams();
    return (
      <>
        <Setup
          key={"edituser?time" + Date.now()}
          command="edituser"
          userId={id}
        />
        <EditUser userId={id} />
      </>
    );
  };
  return (
    <Router>
      <div className="App">
        <Navigation />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/register"
            element={<RegistrationForm mode={Mode.RUN_MODE} user={user} />}
          />
          <Route
            path="/password-recovery"
            element={<PasswordRecovery user={user} />}
          />
          <Route path="/activate/:id" element={<Activation user={user} />} />
          <Route
            path="/setup/:command"
            element={
              <PrivateRoute>
                <Setup
                  key={window.location.pathname + Date.now()}
                  activeDashboard="account"
                />
              </PrivateRoute>
            }
          />
          <Route
            path="/mytasks/:command"
            element={
              <PrivateRoute>
                <Setup
                  key={window.location.pathname + Date.now()}
                  activeDashboard="mytasks"
                />
              </PrivateRoute>
            }
          />
          <Route
            path="/features/:command"
            element={
              <PrivateRoute>
                <Setup
                  key={window.location.pathname + Date.now()}
                  activeDashboard="features"
                />
              </PrivateRoute>
            }
          />
          <Route
            path="/testing/:command"
            element={
              <PrivateRoute>
                <Setup
                  key={window.location.pathname + Date.now()}
                  activeDashboard="testing"
                />
              </PrivateRoute>
            }
          />
          <Route
            path="/defects/:command"
            element={
              <PrivateRoute>
                <Setup
                  key={window.location.pathname + Date.now()}
                  activeDashboard="defects"
                />
              </PrivateRoute>
            }
          />
          <Route
            path="/account/:id"
            element={
              <PrivateRoute>
                <AccountRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/attribute/:id"
            element={
              <PrivateRoute>
                <AttributeRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/billing/:id"
            element={
              <PrivateRoute>
                <BillingRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/board/:id"
            element={
              <PrivateRoute>
                <BoardRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/cloudconfig/:id"
            element={
              <PrivateRoute>
                <CloudConfigRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/codeattribute/:id"
            element={
              <PrivateRoute>
                <CodeAttributeRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/comments/:id"
            element={
              <PrivateRoute>
                <CommentsRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/customfields/:id"
            element={
              <PrivateRoute>
                <CustomFieldsRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/defect/:id"
            element={
              <PrivateRoute>
                <DefectRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/defectdocument/:id"
            element={
              <PrivateRoute>
                <DefectDocumentRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/dockerimage/:id"
            element={
              <PrivateRoute>
                <DockerImageRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/emailsetting/:id"
            element={
              <PrivateRoute>
                <EmailSettingRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/epic/:id"
            element={
              <PrivateRoute>
                <EpicRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/feature/:id"
            element={
              <PrivateRoute>
                <FeatureRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/featuredocument/:id"
            element={
              <PrivateRoute>
                <FeatureDocumentRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/genericcode/:id"
            element={
              <PrivateRoute>
                <GenericCodeRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/listproperty/:id"
            element={
              <PrivateRoute>
                <ListPropertyRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/member/:id"
            element={
              <PrivateRoute>
                <MemberRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/notes/:id"
            element={
              <PrivateRoute>
                <NotesRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/object/:id"
            element={
              <PrivateRoute>
                <ObjectRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/product/:id"
            element={
              <PrivateRoute>
                <ProductRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/:id"
            element={
              <PrivateRoute>
                <ProfileRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/project/:id"
            element={
              <PrivateRoute>
                <ProjectRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/release/:id"
            element={
              <PrivateRoute>
                <ReleaseRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/runschedule/:id"
            element={
              <PrivateRoute>
                <RunScheduleRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/runtag/:id"
            element={
              <PrivateRoute>
                <RunTagRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/scenario/:id"
            element={
              <PrivateRoute>
                <ScenarioRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/sprint/:id"
            element={
              <PrivateRoute>
                <SprintRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/steps/:id"
            element={
              <PrivateRoute>
                <StepsRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/subscription/:id"
            element={
              <PrivateRoute>
                <SubscriptionRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/subtask/:id"
            element={
              <PrivateRoute>
                <SubTaskRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/suitetag/:id"
            element={
              <PrivateRoute>
                <SuiteTagRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/tag/:id"
            element={
              <PrivateRoute>
                <TagRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/taskdocument/:id"
            element={
              <PrivateRoute>
                <TaskDocumentRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/tasks/:id"
            element={
              <PrivateRoute>
                <TasksRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/testcycle/:id"
            element={
              <PrivateRoute>
                <TestCycleRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/testfailure/:id"
            element={
              <PrivateRoute>
                <TestFailureRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/testlog/:id"
            element={
              <PrivateRoute>
                <TestLogRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/testreport/:id"
            element={
              <PrivateRoute>
                <TestReportRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/testresult/:id"
            element={
              <PrivateRoute>
                <TestResultRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/testsuite/:id"
            element={
              <PrivateRoute>
                <TestSuiteRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/timesheet/:id"
            element={
              <PrivateRoute>
                <TimesheetRouteWrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="/user/:id"
            element={
              <PrivateRoute>
                <UserRouteWrapper />
              </PrivateRoute>
            }
          />{" "}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <About />
              </PrivateRoute>
            }
          />
          <Route
            path="/about"
            element={
              <PrivateRoute>
                <About />
              </PrivateRoute>
            }
          />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/terms"
            element={
              <PrivateRoute>
                <TermsAndConditions />
              </PrivateRoute>
            }
          />
          <Route
            path="/privacy"
            element={
              <PrivateRoute>
                <PrivacyPolicy />
              </PrivateRoute>
            }
          />
          <Route
            path="/faq"
            element={
              <PrivateRoute>
                <FAQ />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
