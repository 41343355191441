import React from "react";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>

      <section>
        <h2>Introduction</h2>
        <p>
          Welcome to <strong>Projexlight</strong>. We are committed to
          protecting your privacy. This Privacy Policy explains how we collect,
          use, disclose, and safeguard your information. If you do not agree
          with these terms, please do not use our services.
        </p>
      </section>

      <section>
        <h2>Information We Collect</h2>
        <h3>Personal Information</h3>
        <p>We may collect the following personal data:</p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Profile information</li>
        </ul>

        <h3>Non-Personal Information</h3>
        <p>We also collect non-identifiable information such as:</p>
        <ul>
          <li>Usage data (e.g., pages visited, time spent on pages)</li>
          <li>Device information (e.g., browser type, operating system)</li>
          <li>Cookies and tracking technologies</li>
        </ul>
      </section>

      <section>
        <h2>Use of Information</h2>
        <p>We use the collected information for:</p>
        <ul>
          <li>Providing and maintaining our services</li>
          <li>Notifying you about changes to our services</li>
          <li>Providing customer support</li>
          <li>Improving our services through analytics</li>
          <li>Ensuring security and preventing fraud</li>
        </ul>
      </section>

      <section>
        <h2>Third-Party Service Providers</h2>
        <p>We employ third-party services for:</p>
        <ul>
          <li>AI-powered test case generation</li>
          <li>Payment processing</li>
          <li>Cloud storage and hosting</li>
        </ul>
      </section>

      <section>
        <h2>Security and Data Retention</h2>
        <p>
          We take security seriously and implement strong measures such as
          encryption and access controls. Your data is retained only as long as
          necessary or as required by law.
        </p>
      </section>

      <section>
        <h2>Disclosure of Data</h2>
        <p>We may disclose personal data:</p>
        <ul>
          <li>For legal compliance</li>
          <li>In case of a business transaction (merger, acquisition, etc.)</li>
        </ul>
      </section>

      <section>
        <h2>Your Data Protection Rights</h2>
        <p>Depending on your location, you may have the right to:</p>
        <ul>
          <li>Access your personal data</li>
          <li>Request corrections</li>
          <li>Delete your account and data</li>
          <li>Opt-out of marketing communications</li>
        </ul>
      </section>

      <section>
        <h2>Children's Privacy</h2>
        <p>
          Our service is not intended for children under 13. If we discover that
          a child under 13 has provided us with personal information, we will
          delete it immediately.
        </p>
      </section>

      <section>
        <h2>Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy at any time. Please review it
          periodically for changes.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, contact us at:
        </p>
        <p>
          Email:{" "}
          <a href="mailto:support@projexlight.com">support@projexlight.com</a>
        </p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
